import React from 'react'
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './Card.css'
import Footer from './Footer';
export default function CardN(props) {
  
  var data=props.news;
  var title=props.title;

 
  return (
    <>
          <p id='title'>{title}</p>
    <Container>
        <Row>
        <Col md={12} id='card'>
      
           <Row>
          
           {
            data.map((item,index)=>{
              return(
                <>
                <Col md={6} id='card'>
            <Card key={index}>
            <Card.Body>
        <Card.Title>{item.title}</Card.Title>
        
        <Card.Img variant="top" className='img img-fluid' src={item.urlToImage} id="img" />
        <Card.Text>
                <p>{item.description}</p>
                <p> PublishedAt: {item.publishedAt}</p>
        </Card.Text>
        <a href={item.url} id='link' target='_blank' className='btn btn-primary'>Learn more</a>
      </Card.Body>
     
      
    </Card>
            </Col>
                </>
              )
            })
           }
           </Row>
           
          </Col>
          <Col md={2} id='card'>
          </Col>
           </Row>
        </Container>
      
    </>
  )
}
