import React, { useEffect, useState } from 'react'
import axios from 'axios'
import Navnews from '../Navbar'
import SlideNews from '../SlideNews'
import CardN from '../CardN'
import Footer from '../Footer'
import Search from '../Search'
export default function Technology() {
  var [Data,setData]=useState([])
  useEffect(()=>
    {
     
      fech()
    }
  ,[])
  

function fech ()
{

 
  axios.get(`https://newsapi.org/v2/everything?q=${'technology'}&apiKey=ca48e41a7d3f4d408bb8ce87a9969079`)
  .then(response => {
    // If data is fetched successfully, update the state
       setData(response.data.articles);
  })
  .catch(error => {
    // Handle errors
    console.error('Error fetching data:',error);
  })
  console.log(Data)
};
  return (
      <> 
      
       <Search/>
        <Navnews/>
         <SlideNews headlines={Data}/>
         <CardN title='Technology Brecking' news={Data}/>
         <Footer/>
      </>
  )
}
