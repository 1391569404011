import React, { useState,useEffect } from 'react'
import axios from 'axios'
import SlideNews from './SlideNews'
import './Search.css'
import Navnews from './Navbar'
import CardN from './CardN'
import Footer from './Footer'
import Find from './Find'
import Search from './Search'
export default function Main(props) {
    
     var [Data,setData]=useState([])
      useEffect(()=>
        {
         
          fech('us')
        }
      ,[])
      

  function fech (us)
  {
   
     
      axios.get(`https://newsapi.org/v2/top-headlines?country=${us}&apiKey=ca48e41a7d3f4d408bb8ce87a9969079`)
      .then(response => {
        // If data is fetched successfully, update the state
           setData(...Data,response.data.articles);
      })
      .catch(error => {
        // Handle errors
        console.error('Error fetching data:',error);
      })
    };
 
  return (
    <> 
          <Search/>
         <Navnews/>
         <SlideNews headlines={Data}/>
         <CardN title='Brecking News' news={Data}/>
         <Footer/>
         
    </>
  )
}
