import React, {useState } from 'react';
import FecthApi from './NodeApi'
import Count from './componant/count';
import Validation from './Validation';
import Controlled from './Controlled';
import { BrowserRouter,Route,Router } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import Home from './Home';
import About from './About';
import Project from './componant/Project';
import Demo from './componant/Demo';
import { createContext } from 'react';
import ChildA from './ChildA';
import Nav from './Theam/Nav';
import Router_ from './NewsApp/Router_';
import { Button, Container } from 'react-bootstrap';
import Navnews from './NewsApp/Navbar';
 export default function App()
  {
   /* var name="chandan kumar"
    var data=createContext()*/
   return (
     
    <>  
  
    <BrowserRouter>
    <Router_/>
    </BrowserRouter>   
        
          
    </>
    
   
   )
  
 }


 /* propes drilling is way to passed data one compmponet to another component in nested way.
 contextApi is package of rect js which is used to passed data or propes easlly without nexted component.

 */
 
   