import React from 'react'
import Search from './Main'
import India from './Naveitem/India'
import America from './Naveitem/America'
import Business from './Naveitem/Business'
import { BrowserRouter ,Route,Router,Routes } from 'react-router-dom'
import Politics from './Naveitem/Politics'
import Technology from './Naveitem/Technology'
import Science from './Naveitem/Science'
import Sport from './Naveitem/Sport'
export default function Router_() {
  return (
    <>
       <Routes>
        <Route path='/' element={<Search/>}/>
        <Route path='/India' element={<India/>}/>
        <Route path='/America' element={<America/>}/>
        <Route path='/politics' element={<Politics/>}/>
        <Route path='/Business' element={<Business/>}/>
        <Route path='/Technology' element={<Technology/>}/>
        <Route path='/Science' element={<Science/>}/>
        <Route path='/Sport' element={<Sport/>}/>
       </Routes>
      
    </>
  )
}
