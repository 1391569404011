import { Carousel } from 'bootstrap';
import React from 'react'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import SlideNews from './SlideNews';
import './Nav.css'
import Api from './Api';
import { NavLink } from 'react-router-dom';
export default function Navnews() {
  return (
    <>
         <nav className="navbar navbar-expand-lg navbar-dark" id='nav'>
  <a className="navbar-brand" href="#" id='head'>  <span id='f'>World</span> <span id='s'>Public</span> <span id='t'>News</span> </a>
  <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
    <span className="navbar-toggler-icon"></span>
  </button>
  <div className="collapse navbar-collapse" id="navbarNav">
    <ul className="navbar-nav">
      <li className="nav-item active">
        <NavLink to="/" className="nav-link">Home <span className="sr-only">(current)</span></NavLink>
      </li>
      <li className="nav-item">
        <NavLink to="/India" className="nav-link">India</NavLink>
      </li>
      <li className="nav-item">
        <NavLink to="/America" className="nav-link" href="#">American</NavLink>
      </li>
      <li className="nav-item">
        <NavLink to='/politics' className="nav-link">Politics</NavLink>
      </li>
      <li className="nav-item">
        <NavLink to='/Business' className="nav-link" href="#">Business</NavLink>
      </li>
      <li className="nav-item">
        <NavLink to="/technology" className="nav-link">Technology</NavLink>
      </li>
      <li className="nav-item">
        <NavLink to="/Science" className="nav-link">Science</NavLink>
      </li>
     
      <li className="nav-item">
      <NavLink to="/Sport" className="nav-link">Sports</NavLink>
      </li>
      
    </ul>
  </div>
</nav>
   
    </>
  )
}
