import React from 'react'
import Api from './Api'
import './Slide.css'
import { Carousel } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import CardN from './CardN'

export default function SlideNews(props) {
  
var data=props.headlines;




  return (
    <>
 
 <div id="carouselExampleSlidesOnly" className="carousel slide" data-ride="carousel">
  <div className="carousel-inner" id='carea'>
    <div className="carousel-item active">
      <img className="d-block" id='pic' src="https://www.shutterstock.com/image-vector/breaking-news-background-world-global-260nw-719766118.jpg" alt="First slide"/>
       <div className='carousel-caption d-none d-md-block'>
       </div>
    </div>
        {
          data.map((item,index)=>
          {
            return(
              <>
              <div className="carousel-item" key={index}>
               <img className="d-block" id='pic' src={item.urlToImage} alt="Second slide"/>
               
               <div className='carousel-caption d-none d-md-block'>
              <h1 className='heading'>{item.description}</h1>
              <p className='discription'>{item.content}</p>
              <p>published:-{item.publishedAt}</p>
               <a className='btn' href={item.url} id='btn'>Read More</a>
               </div>
              </div>
              </>
            )
          })
        }
  </div>
</div>
   
    </>
  )
}
