import React from 'react'
import "./Footer.css"
import { FaInstagram } from "react-icons/fa6";
import { FaFacebook } from "react-icons/fa";
import { CgMail } from "react-icons/cg";
export default function Footer() {
  return (
   <>
    <footer className="news-footer">
      <div className="footer-container">
        <div className="footer-section">
          <h3>About Us</h3>
          <p>Mr.Chandan kumar is a leading source of reliable news and information, dedicated to delivering accurate and unbiased reporting on local, national, and global events. With a commitment to journalistic integrity, we strive to empower our readers with knowledge and insights that matter.</p>
        </div>
        <div className="footer-section">
          <h3>Contact Us</h3>
          <p>Email: yaduvanshichandankumar158@gmail.com</p>
          <p>Phone: 9523025068</p>
        </div>
        <div className="footer-section">
          <h3>Follow Us</h3>
          <div className="social-icons">
            <a href="https://www.instagram.com/yaduvansi_chandan_9523/"> <FaInstagram /> </a>
            <a href="https://www.instagram.com/yaduvansi_chandan_9523/">   <FaFacebook/>  </a>
            <a href="mailto:yadavchandankumar158@gmail.com" target='blank'> <CgMail />  </a>
          </div>
        </div>
      </div>
    </footer>
   </>
  )
}
