import React, { useState } from 'react'
import './Search.css'
import Find from './Find'

export default function Search()
 {
    const [data,setdata]=useState('')
    var [check,setcheck]=useState(false)

    function handle(e)
    {
        setdata(e.target.value)
        console.log(data)
    }
  return (
    <>
          <div className='search'>
               <div classNameName="search-container d-flax">
                   <input type="text" name='search' value={data} id="search-input" onChange={handle}   placeholder="Search..."/>
                   <button className="search-button" onClick={()=>{setcheck(true)}}>Search</button>
                </div>
               
         </div>
         {
            check?
            <div className='find'>
                  <img src='https://logowik.com/content/uploads/images/close1437.jpg' onClick={() => { setcheck(false) } }></img>
                 <Find data={data}/>
              </div>:<></>
         }
    </>
  )
}
