import React, { useState,useEffect } from 'react'
import './Find.css'
import axios from 'axios'
import SlideNews from './SlideNews'
import Footer from './Footer'
import CardN from './CardN'
export default function Find(props) {
    const [data,setdata]=useState(true)
    const url=`https://newsapi.org/v2/everything?q=${props.data}&apiKey=ca48e41a7d3f4d408bb8ce87a9969079`

    var [Data,setData]=useState([])
    useEffect(()=>
      {
       
        fech()
      }
    ,[])
    

function fech ()
{
 
   
    axios.get(`${url}`)
    .then(response => {
      // If data is fetched successfully, update the state
         setData(response.data.articles);
    })
    .catch(error => {
      // Handle errors
      console.error('Error fetching data:',error);
    })
  };
 console.log(Data)

  return (
    <>
    {
       
                 <CardN news={Data}/>
             
    }
        
    </>
  )
}

